.footer {
    background-color: #1c1c1c; 
    color: white;
    padding: 40px 0;
    text-align: center;
    position: relative; 
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px; 
  }
  
  .footer-logo {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer-description {
    font-size: 1rem;
    margin-bottom: 20px;
    opacity: 0.8; 
  }
  
  .footer-description a {
    color: #ff4500;
    text-decoration: none;
  }
  
  .footer-description a:hover {
    color: #e03c00; 
  }
  
  .footer-links {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer-links a {
    color: #ff4500; 
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s; 
  }
  
  .footer-links a:hover {
    color: #e03c00; 
    text-decoration: underline;
  }
  
  .footer-content p {
    margin: 5px 0; 
  }
  
  .footer-bottom {
    margin-top: 30px;
    font-size: 0.9rem; 
    opacity: 0.7; 
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 15px;
  }
  