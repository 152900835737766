.faq {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .faq h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .faq-list {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0;
  }
  
  .faq-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .faq-item p {
    font-size: 1.2rem;
  }
  