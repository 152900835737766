.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  color: white;
  position: relative;
  z-index: 100;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links a, .subscribe-btn {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links a:hover, .subscribe-btn:hover {
  color: #ff4500;
}

.subscribe-btn {
  background-color: #ff4500;
  color: white;
  padding: 8px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
  background-color: #e03c00;
}

/* Language Selector Styling */
.lang-selector {
  position: relative;
}

.lang-selector select {
  background-color: #282c34;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.lang-selector select:hover {
  background-color: #ff4500;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
}

.bar {
  height: 4px;
  background-color: white;
  border-radius: 2px;
  width: 100%;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    background-color: white;
    padding: 20px;
    display: none;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .nav-links a:hover {
    color: #007BFF;
  }

  .subscribe-btn {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }

  .subscribe-btn:hover {
    background-color: #0056b3;
  }

  .lang-selector select {
    background-color: #007BFF;
    color: white;
    font-size: 1.2rem;
    padding: 8px 15px;
    border-radius: 5px;
  }
}
