
.video-gallery {
    padding: 50px 20px;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .video-gallery h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .video-gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .video-gallery-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .video-gallery-grid {
      grid-template-columns: 1fr;
    }
  }
  