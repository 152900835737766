.about-us {
  font-family: Arial, sans-serif;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 60vh; 
  overflow: hidden;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
}

.hero-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-overlay p {
  font-size: 1.2rem;
}

.mission-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.mission-section .container {
  max-width: 1000px;
  margin: 0 auto;
}

.mission-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.mission-section p {
  font-size: 1.2rem;
  line-height: 1.8;
}

.values-section {
  padding: 50px 20px;
  background-color: #fff;
}

.values-section .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.values-section h2 {
  text-align: center;
}

.values-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.value-item {
  width: 250px;
  text-align: center;
}

.value-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.value-item p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.achievements-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.achievements-section .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.achievements-section h2 {
  text-align: center;
}

.achievements-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.achievement-item {
  width: 250px;
  text-align: center;
}

.achievement-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #ff4500;
}

.achievement-item p {
  font-size: 1.1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hero-section {
    height: 40vh;
  }

  .hero-overlay h1 {
    font-size: 2rem;
  }

  .hero-overlay p {
    font-size: 1rem;
  }

  .value-item, .achievement-item {
    width: 100%;
  }
  
  .mission-section h2 {
    font-size: 2rem;
  }
  
  .mission-section p {
    font-size: 1rem;
  }
}
