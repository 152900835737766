/* src/components/ContactUs/ContactUs.css */
.contact-us {
    padding: 50px 20px;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .contact-us h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .contact-us form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .contact-us input,
  .contact-us textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-us button {
    padding: 10px 20px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-us button:hover {
    background-color: #e03c00;
  }
  