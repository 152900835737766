.subscription-benefits {
    padding: 50px 20px;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .subscription-benefits h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .subscription-benefits ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
  }
  
  .subscription-benefits ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .cta-btn {
    padding: 12px 30px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .cta-btn:hover {
    background-color: #e03c00;
  }
  