.hero-section {
  position: relative;
  width: 100%;
  height: 80vh; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-slide img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
}

.hero-overlay h1 {
  color: white;
  font-size: 4rem;
  margin-bottom: 30px;
}

.hero-overlay p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.cta-btn {
  padding: 15px 40px;
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
}

.cta-btn:hover {
  background-color: #e03c00;
}

.pricing-info {
  font-size: 0.9rem;
  margin-top: 10px;
  color: #fff;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .hero-section {
    height: 100vh; 
  }

  .hero-overlay {
    padding: 20px;
    max-width: 90%; 
  }

  .hero-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .hero-overlay p {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .cta-btn {
    padding: 12px 35px;
    font-size: 1.3rem;
  }

  .pricing-info {
    font-size: 0.8rem;
  }

  .slick-slide img {
    height: 100vh; 
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 100vh; 
  }

  .hero-overlay {
    background-color: transparent; 
    padding: 0;
    max-width: 95%; 
  }

  .hero-overlay h1 {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }

  .hero-overlay p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .cta-btn {
    padding: 10px 20px; 
    font-size: 1rem;
  }

  .pricing-info {
    font-size: 0.7rem;
  }

  .slick-slide img {
    height: 100vh; 
  }
}
