.terms-page {
  padding: 30px 20px;
  width: 100%;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9; /* Subtle background for contrast */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.terms-page h1 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 20px;
  text-align: left;
  border-left: 5px solid #ff4500; /* Visual indicator */
  padding-left: 10px;
}

.terms-page h2 {
  font-size: 1.8rem;
  color: #444;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  text-align: left;
}

.terms-page p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: left;
}

.terms-page a {
  color: #ff4500;
  text-decoration: none;
  font-weight: bold;
}

.terms-page a:hover {
  text-decoration: underline;
}

/* Subsections styling */
.terms-page h3 {
  font-size: 1.5rem;
  color: #666;
  margin-top: 25px;
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}


.terms-page ul {
  padding-left: 20px;
  list-style-type: disc;
  margin-bottom: 15px;
}

.terms-page ul li {
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.6;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .terms-page {
    padding: 20px;
  }

  .terms-page h1 {
    font-size: 2rem;
  }

  .terms-page h2 {
    font-size: 1.5rem;
  }

  .terms-page h3 {
    font-size: 1.3rem;
  }

  .terms-page p {
    font-size: 0.95rem;
  }

  .terms-page ul {
    padding-left: 15px;
  }
}
