.sign-in-page {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    max-width: 100%;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    box-sizing: border-box;
    text-align: center;
  }
  
  .sign-in-page form {
    width: 100%;
    max-width: 400px; 
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    box-sizing: border-box;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 20px;
  }
  
  .intro-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .sign-in-btn {
    width: 100%;
    padding: 15px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sign-in-btn:hover {
    background-color: #e03c00;
  }
  
  .success-message, .error-message {
    text-align: center;
    margin-top: 10px;
    font-size: 1rem;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  
  .extra-info {
    text-align: center;
    margin-top: 20px;
  }
  
  .extra-info h2 {
    font-size: 1.8rem;
    color: #222;
  }
  
  .subscription-link {
    color: #ff4500;
    cursor: pointer;
    font-weight: bold;
  }
  
  .subscription-link:hover {
    text-decoration: underline;
  }
  
  .terms-note {
    text-align: center;
    margin-top: 30px;
  }
  
  .terms-note h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .terms-note ul {
    list-style-type: none;
    padding: 0;
  }
  
  .terms-note ul li {
    margin-bottom: 10px;
  }
  
  .terms-note a {
    color: #ff4500;
    text-decoration: none;
    font-weight: bold;
  }
  
  .terms-note a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sign-in-page {
      padding: 20px;
      margin-bottom: 1rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .intro-text {
      font-size: 0.9rem;
    }
  
    .sign-in-btn {
      padding: 12px;
      font-size: 1rem;
    }
  
    .terms-note h2, .extra-info h2 {
      font-size: 1.3rem;
    }
  
    .terms-note ul li {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.8rem;
    }
  
    .intro-text {
      font-size: 0.85rem;
    }
  
    .sign-in-btn {
      padding: 10px;
      font-size: 0.95rem;
    }
  
    .terms-note ul li {
      font-size: 0.85rem;
    }
  }
  .extra-info .subscription-link {
    margin-left: 5px; /* Adjust as needed for spacing */
  }
  