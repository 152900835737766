.pricing {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .pricing h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .pricing-card {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto; 
  }
  
  .pricing-card h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .pricing-card p {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .cta-btn {
    padding: 10px 25px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .cta-btn:hover {
    background-color: #e03c00;
  }
  
  .subscription-notice {
    margin-top: 30px;
    font-size: 1rem;
    color: #555;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; 
  }
  