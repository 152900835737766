.testimonials {
    padding: 50px 20px;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .testimonial-list {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  