
.game-gallery {
  padding: 50px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.game-gallery h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.game-card {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-end;
  transition: transform 0.3s ease;
}

.game-card:hover {
  transform: scale(1.05); 
}

.game-card-overlay {
  width: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
  color: white;
  padding: 20px;
  text-align: center;
}

.game-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.game-btn {
  padding: 10px 20px;
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.game-btn:hover {
  background-color: #e03c00;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }
}
