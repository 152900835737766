
.game-card {
    position: relative;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-end;
    transition: transform 0.3s ease;
  }
  
  .game-card:hover {
    transform: scale(1.05); 
  }
  
  .game-card-overlay {
    width: 100%;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .game-card-title {
    color: white;

    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .game-card-btn {
    padding: 10px 20px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .game-card-btn:hover {
    background-color: #e03c00;
  }
  