.cookie-policy-page {
    padding: 30px 20px;
    width: 100%;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    box-sizing: border-box;
    overflow-x: hidden;
}

.cookie-policy-page h1 {
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 20px;
    text-align: left;
    border-left: 5px solid #ff4500;
    padding-left: 10px;
}

.cookie-policy-page h2 {
    font-size: 1.8rem;
    color: #444;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    text-align: left;
}

.cookie-policy-page p {
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 15px;
    text-align: left;
}

.cookie-policy-page a {
    color: #ff4500;
    text-decoration: none;
    font-weight: bold;
}

.cookie-policy-page a:hover {
    text-decoration: underline;
}

.cookie-policy-page ul {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 15px;
}

.cookie-policy-page ul li {
    font-size: 1rem;
    margin-bottom: 8px;
    line-height: 1.6;
    text-align: left;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .cookie-policy-page {
        padding: 20px;
    }

    .cookie-policy-page h1 {
        font-size: 2rem;
    }

    .cookie-policy-page h2 {
        font-size: 1.5rem;
    }

    .cookie-policy-page p {
        font-size: 0.95rem;
    }

    .cookie-policy-page ul {
        padding-left: 15px;
    }
}
